import { TypeName } from './page-slot'

export class BlocksListModel extends TypeName {
  constructor(item = null) {
    super(item);
    this.sectionId = item.sectionId
    this.blocks = item.blocks.map((block) => {
      return new BlockModel(block);
    })
  }
}

export class BlockModel {
  constructor(item = null) {
    if (item) {
      this.name = item.name;
      this.order = item.order;
      this.body = item.body;
    }
  }
}